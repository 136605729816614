import React from 'react'

import PropTypes from 'prop-types'

import './layout.css'

const Layout = ({ auth, children, type = 1 }) => (
  <div className={type === 1 ? 'page-wrapper' : 'page-wrapper-2'}>
    {children}
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
